<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">View Status</h2>
        <v-row>
            <v-col cols="12" >
                <v-card outlined elevation="2" style="padding: 10px;" class="blue lighten-5">
                    <p class="main_p">Status Request</p>
                    <div v-if="leave != null">
                    <p class="sub_p">Leave Request : <strong>{{ updateStatus(leave.status) }}</strong></p>
                    <p class="sub_p">Leave Type : <strong>{{ updateType(leave.type) }}</strong></p>
                    <p class="sub_p">Date From : <strong>{{ leave.date_from }}</strong></p>
                    <p class="sub_p">Date To : <strong>{{ leave.date_to }}</strong></p>
                    <p class="sub_p">Comment : <strong>{{ leave.comment }}</strong></p>
                    </div>
                    <p v-if="leave == null" style="color:red; margin-top:10px; text-align:center;">No leave request found</p>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<style scoped>
.main_p{
    font-size: 19px !important;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.sub_p{
    padding-bottom: 0px;
    margin-bottom: 0px;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: "LeaveStatus",
    data: () => ({}),
    computed:{
        ...mapGetters ({ leave: "getLeaveStatus" })
    },
    mounted(){
        let user = JSON.parse(localStorage.getItem('user'))
        var payload = user.type+'/'+user.id
        this.fetchLeaveStatus(payload)
    },
    methods: {
        ... mapActions(['fetchLeaveStatus']),
        updateType(value) {
            var result = "";
            switch(value) {
                case "paid": result = "Paid Leave" 
                break;
                case "nopay": result = "No Pay Leave" 
                break;
                case "sick": result = "Sick Leave" 
                break;
                case "paternity": result = "Paternity Leave" 
                break;
                case "maternity": result = "Maternity Leave" 
                break;
                case "other": result = "Other type of Leave" 
                break;
                default: result = "Loading ..."
            }
            return result;
        },
        updateStatus(value) {
            var result = "";
            switch(value) {
                case "pending": result = "Under Review" 
                break;
                case "accept": result = "Accepted" 
                break;
                case "reject": result = "Rejected" 
                break;
                default: result = "Loading ..."
            }
            return result;
        }
    }
}
</script>